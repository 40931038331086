/* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600&display=swap'); */

/*Pistä app.tsx global tyyleihin? */
body {
    /*display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;*/
    box-sizing: border-box;
    height: 100%;
}

#root {
    background-color: #F7F7F8;
    height: 100%;
    padding-bottom: 30px;
    background-image: url('/pics/luonnos2.png');
    /* background-size: 160px 110px;  */
    background-repeat: repeat;
    
}

.text-columns-2, .text-columns-3
{
    column-gap: 100px;
    column-rule: #ccc solid 1px;
    width: 100%;
}
.text-columns-2 { column-count: 2; }
.text-columns-3 { column-count: 3; }


.MuiCollapse-root,
.MuiCardContent-root {
    background: rgba(245, 245, 245, 0.5);
}

.MuiPaper-root {
    border: 1px solid #C8CDD0;
}

.MuiCard-root {
/*    border: 1px solid #C8CDD0;*/
    margin: 0px 0 16px 0;
}


.MuiPaper-root:not(.MuiCard-root, .MuiAccordion-root) {
    padding: 20px;
    /* border-radius: 7px 7px 0px 0px;
    box-shadow: -3px 3px 4px #C8CDD0; */
    /*border-radius: 7px;*/
}

.MuiPaper-root>.MuiTypography-h1 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
    color: #222222;
    margin-bottom: 16px;
}

.MuiTypography-h2 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: #000000;
}

.MuiTypography-h6 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #000000;
}

.MuiPaper-root>.MuiTypography-body1 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
}



/*a[href="/"]:visited {
    color: #2A6EBB;
}*/
a:visited {
    color: #2A6EBB !important;
}

/* .MuiPaper-root>.MuiTypography-h2, */
.MuiBox-root>.MuiTypography-h2  {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #000000;
}

.MuiAccordionSummary-content>.MuiTypography-h3 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    /* color: #000000; */
    color: #222222;
}

.MuiCardHeader-title.MuiTypography-h5 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    /* identical to box height, or 127% */

    color: #000000;
}

.LabelValueBlock-title.MuiTypography-h4 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    
    
    /* Main/blackBase */
    
    color: #222222;
}



.LabelValue-label {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* Main/blackBase */

    color: #222222;
}

.LabelValue-value {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height, or 150% */

    /* Main/blackBase */

    color: #222222;
}

.oma-radio-button {
    margin: 8px 0 0 0;
    border-radius: 15px;
}

.oma-radio-button:has(>.Mui-checked) {
    background: #EFF0F1;
    border: 1px solid #A5ADB1;
}

.oma-radio-button:hover {
    background: #F0F6FF;
}

.BuildingTooltip>.MuiTooltip-tooltip  {
    color: black;
    background-color: white;
    border-top: #1A99C7 solid 4px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(41, 41, 41, 0.2);
    border-radius: 3px;

    padding: 20px 15px;
    line-height: 24px;

    position: absolute;
    width: 290px;
}